import React from "react";
import Layout from "../components/layout";
import AuditHeroSection from "../components/auditherosection";
import { Helmet } from "react-helmet";
import icondata from "../images/icon-data.svg";
import iconroadmap from "../images/icon-roadmap.svg";
import iconcomputers from "../images/icon-computers.svg";
import iconcode from "../images/icon-code.svg";
import heroimage from "../images/conversion-rate-optimisation-audit-hero.jpg";
import audithelpdevice from "../images/conversion-rate-optimisation-audit-help-device.png";
import audithelpscorecard from "../images/conversion-rate-optimisation-audit-help-scorecard.png";
import iconmagnifyingglass from "../images/icon-magnifying-glass.svg";
import icongraph from "../images/icon-graph.svg";

import iconquantitativeanalysis from "../images/icon-quantitative-analysis.svg";
import iconuxanalysis from "../images/icon-ux-analysis.svg";
import icontechandplatformanalysis from "../images/icon-tech-and-platform-analysis.svg";
import iconbuildtestingandimplementationroadmap from "../images/icon-build-testing-and-implementation-roadmap.svg";

import "../scss/conversionRateOptimisationAudit.scss";
import "../scss/auditHeroSection.scss";

const AuditConversionRateOptimisation = () => {
    const metadescription = "Conversion Rate Optimisation Audit - Next&Co";
    const metatitle = "Conversion Rate Optimisation Audit - Next&Co";
    const metaurl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription}/>
            <meta name="title" content={metatitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaurl} />
            <meta property="og:title" content={metatitle} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="facebook.png" />
            <meta property="twitter:url" content={metaurl} />
            <meta property="twitter:title" content={metatitle} />
            <meta property="twitter:description" content={metadescription} />
            <meta property="twitter:image" content="twitter.png" />
          </Helmet>
          <div id="conversion-rate-optimisation-audit">
            <Layout>
              <section id="row-one">
                <div className="outer-container">
                  <div className="container-max-width">
                    <div className="inner-container">
                      <div className="col-one">
                        <h1>Conversion Rate Optimisation Audit</h1>
                      </div>
			<div className="col-two"
			     style={{
				 display: `flex`,
				 alignItems: `center`,
			     }}>
                            <p>
				Analyse and benchmark your audience’s digital conversion
				journey, with a roadmap to continuous improvement
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="audit-hero-section"
                style={{
                    backgroundImage: `url(${heroimage})`,
                    backgroundPosition: `center center`,
                    backgroundAttachment: `fixed`,
                }}
              >
                <div className="outer-container">
                  <div className="container-max-width">
                    <div className="inner-container">
                      <div className="col-one">
                        <div className="list-item">
                          <div className="icon">
                            <img src={iconquantitativeanalysis} alt="" />
                          </div>
                          <div className="content">
                            <h2>
                              Quantitative Analysis 
                            </h2>
                            <p>
                              Each step in your digital experience is assessed and benchmarked with an overall conversion uplift opportunity quantified
                            </p>
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="icon">
                            <img src={iconuxanalysis} alt="" />
                          </div>
                          <div className="content">
                            <h2>
                              UX Analysis
                            </h2>
                            <p>
                              A heuristic deep dive into your current digital experience to put context to the quantitative analysis
                            </p>
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="icon">
                            <img src={icontechandplatformanalysis} alt="" />
                          </div>
                          <div className="content">
                            <h2>
                              Tech & platform Analysis
                            </h2>
                            <p>
                              An assessment of the platforms & technology that enables your digital experience and would inform the implementation of your roadmap
                            </p>
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="icon">
                            <img src={iconbuildtestingandimplementationroadmap} alt="" />
                          </div>
                          <div className="content">
                            <h2>
                              Build testing & implementation roadmap
                            </h2>
                            <p>
                              Implementation roadmap - Define a testing and implementation schedule to realise conversion uplift
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-two">
                        <div className="form-container">
                          <h2>Enquire about a conversion rate optimisation audit for your brand</h2>
                          {/* <h1>{this.props.formSubmitUrl}</h1> */}
                          <form
                            name="cro-audit"
                            method="post"
                            action="/croa-thankyou/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                          >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="cro-audit" />
                            <p hidden>
                              <label>
                                Don’t fill this out:{" "}
                                <input name="bot-field"/>
                              </label>
                            </p>
                            <p></p>
                            <p>
                              <label>
                                Your Name
                                <input
                                  type="text"
                                  required
                                  name="your-name"
                                  placeholder="Your name"
                                />
                              </label>
                            </p>
                            <p>
                              <label>
                                URL
                                <input
                                  type="text"
                                  required
                                  name="website-url"
                                  placeholder="Website URL"
                                />
                              </label>
                            </p>
                            <p>
                              <label>
                                Email
                                <input
                                  type="email"
                                  required
                                  name="email"
                                  placeholder="Your email"
                                />
                              </label>
                            </p>
                            <p>
                              <label>
                                Phone
                                <input
                                  type="tel"
                                  name="phone"
                                  required
                                  placeholder="Your phone"
                                />
                              </label>
                            </p>
                            <p>
                              <button className="btn btn-invert" type="submit">
                                Get your audit
                              </button>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="audit-help">
                <div className="outer-container">
                  <div className="container-max-width">
                    <div className="inner-container">
                      <div className="col">
                        <img src={audithelpdevice} alt="" />
                      </div>
                      <div className="col">
                        <img src={audithelpscorecard} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </div>
        </>
    )
}

export default AuditConversionRateOptimisation
